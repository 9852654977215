.container{
    width: 1378px;
    overflow-x: hidden;
}



/* mobiles */
@media only screen and (max-width: 1280px) {
    .container {
        width:100%;
        padding: 0 15px;
    }
}

/* tablets */
@media only screen and (min-width: 1280px) {
    .container {
        width: 1200px;
    }
}


/* desktops */
@media only screen and (min-width: 1400px) {
    .container {
        width: 1378px;
    }
}

