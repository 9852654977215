.logo {
    font-size: 40px;
    font-weight: 400
}

/* tablets */
@media only screen and (max-width: 1200px) {

    .logo {
        /*font-size: 25px;*/
        height: 19px;
    }
}